.check-status {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: -8px;
  margin-bottom: -50px;
}

.bool_top {
  display: grid;
  grid-template-columns: 32% 32% 32%;
}

.check-status > * {
  margin: 0 30px 30px 30px;
}

.ui.checkbox label:before,
.ui.checkbox label:after {
  right: 0px;
  left: auto;
}

.ui.checkbox label {
  padding: 0;
  padding-right: 24px;
}

.photo-label {
  margin: 0px auto;
}

.heading {
  margin: 0 0 0.28571429rem 0;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.92857143em;
  font-weight: 700;
  text-transform: none;
  display: inline;
}

#root {
  min-height: 100vh;
}

html {
  background-image: url("../../assets/garden.png");
  background-repeat: repeat;
  background-size: cover;
  height: 100%;
}

.signUpForm {
  border-radius: 50px;
  background-color: white;
  margin: auto;
  margin: 5%;

  /* margin: calc((100vh - 117px - 154px)/8) 5%; */
  resize: both;
}

.chakra-ui-light {
  background-color: transparent;
  background: transparent;
}
