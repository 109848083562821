.wholePage {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.conTable {
  padding: 2%;
}

h1 {
  font-size: xx-large;
}

.AdminHeader {
  background-color: white;
  font-size: x-large;
  color: #576754;
  font-family: "Days One";
  width: 40%;
  margin-left: 2%;
  padding-left: 20%;
}

.csvStack {
  background-color: rgba(255, 255, 255, 0.45);
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 1px 0px 0px rgb(161, 161, 161);
  backdrop-filter: blur(60px);
  margin-left: 10%;
  margin-right: 10%;
}

.TopLabel {
  background-color: rgba(255, 255, 255, 0.45);
  color: #576754;
  font-size: 45px;
  display: flex;
  justify-content: space-between;
  margin-left: 7%;
  margin-right: 7%;
  margin-top: 2%;
  padding-left: 2%;
  border-radius: 10px;
  backdrop-filter: blur(60px);
  padding: 20px;
}

.status {
  background-color: #576754;
  color: white;
  margin: 10px;
  padding: 1%;
  border-radius: 5px;
}
.hName {
  margin: 10px;
  padding: 1%;
}
