td {
  padding: 10px;
}

td,
th {
  border-left: none;
  border-right: none;
  clip-path: inset(-10px 0px -10px 0px);
}

.pagination {
  background-color: #ccddbd;
  padding: 10px;
  display: flex;
  justify-content: space-evenly;
}

.export {
  border-radius: 10px;
  color: rgb(87, 103, 84);
  background: rgb(230, 242, 217);
  padding: 10px;
}
