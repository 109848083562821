.page {
  background-color: rgb(230, 242, 217);
}

.container {
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);

  height: 100%;
  background-color: rgba(255, 255, 255, 0.45);
  border-radius: 10px;
  box-shadow: 0 1px 0px 0px rgb(161, 161, 161);
  margin-right: 5%;
  margin-left: 5%;
  backdrop-filter: blur(60px);

}

.topRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#title {
  color: #576754;
  font-size: large;
  display: inline-flex;
  padding: 1%;
}

#numHours {
  background-color: #576754;
  color: white;
  font-weight: bold;
  padding: 5px;
  display: inline-flex;
  float: right;
  font-size: large;
  border-radius: 0.5em;
  margin: 10px;
}

#header {
  background-color: #576754;
  color: white;
}

.fixed_header {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.fixed_header tbody {
  display: block;
  width: 100%;
  overflow: auto;
  height: 150px;
}

.fixed_header thead tr {
  display: block;
}

.fixed_header thead {
  background: black;
  color: #fff;
}

.fixed_header th,
.fixed_header td {
  padding: 5px;
  text-align: center;
  width: 200px;
}
