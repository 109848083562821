.profInfoI {
  display: flex;
  background-color: rgba(255, 255, 255, 0.45);
  flex-direction: column;
  justify-content: space-around;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 1px 0px 0px rgb(161, 161, 161);
  backdrop-filter: blur(60px);
  width: 100%;
}

.header {
  display: flex;
  justify-content: center;
  font-size: 75px;
  color: white;
  border-radius: 10px;
}

.name {
  background-color: #576754;
  color: #cee4bb;
  font-size: 40px;
  padding: 5px;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  margin-top: 10%;
}

.profInfoG {
  background-color: rgba(255, 255, 255, 0.45);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 1px 0px 0px rgb(161, 161, 161);
  backdrop-filter: blur(60px);
  width: 100%;
}

.left {
  margin: 1%;
}

.right {
  margin: 1%;
}

.BarHeader {
  font-size: 15px;
  display: flex;
  padding: 10px;
  font-weight: 1000;
}

.value {
  font-weight: 300;
  padding-bottom: 5px;
}

p {
  padding: 1%;
}

.SideBar {
  margin-left: 10%;
  margin-right: 10%;
}
.info {
  display: block;
  width: 100%;
  overflow: auto;
  height: 30em;
}
