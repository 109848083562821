.navFlex {
  display: flex;
}

#love {
  padding-top: 3em;
  padding-left: 1em;
  padding-bottom: 0.5em;
  color: #ffffff;
  font-size: 1.75em;
  font-weight: 400;
}


.footerNav {
  position: relative;
  bottom: 0;
}

.footer {
  display: flex;
  justify-content: space-between;
  background-color: #ccddbd;
}
