* {
  font-family: "Poppins", sans-serif;
}

.box {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

h2 {
  font-size: 20px;
  color: #465344;
  text-align: center;
}

/* .announcements {
  overflow: auto;
  min-height: 18em;
} */

.announcementForm {
  padding-bottom: 10%;
}
